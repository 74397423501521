import React, { HTMLAttributes } from 'react';
import { useWidget, ModelProvider, useModel } from '../core/hooks';
import ErrorBounds from './error-bounds';
import { Addons } from './addon';
import { isString } from '../utils';

interface WidgetNodeProps {
  /** Model key */
  id: string;
  // model: IContent;
  container?: HTMLDivElement;
  children?: React.ReactNode;
  // hostRef: React.RefObject<HTMLDivElement>;
  className?: string;
  fixed?: boolean;
  section?: boolean;
}

interface NodeChildrenProps {
  ids?: string[];
  section?: boolean;
}

function WidgetNode({ id, section, fixed }: WidgetNodeProps) {
  const model = useModel(id);
  const hostRef = React.useRef<HTMLDivElement>(null);
  const Widget = useWidget(model && (model.type as string));
  if (!model || !model.type) return null;

  let cl = '__' + model.type;
  let childrenCl = '__c';
  const contentProps: HTMLAttributes<HTMLDivElement> = {
    id: model.key + 'c',
    className: model.cl,
  };

  if (isString(model.master)) {
    cl += ` ${model.master}`;
    contentProps.className += ` ${model.master}`;
  } else if (model.master) cl += ` ${model.key}`;

  if (model.fromModel) {
    if (!model.master) contentProps.className += ` ${model.fromModel}`;
    cl += ` ${model.fromModel}`;
  }

  if (fixed) cl += ' __f';
  if (section) {
    cl += ' __s';
    childrenCl += ' __sc';
  } else if (model.html || (Widget && (Widget as any).static)) cl += ' __pre'; // Check if widget has added static attribute;

  if (model.html) contentProps.dangerouslySetInnerHTML = { __html: '' };

  if (model.type === 'frame') {
    contentProps.className += ' __wc __c';

    return React.createElement(
      'vev',
      { id: model.key, className: cl, style: model.style, ref: hostRef },
      <Addons addons={model.actions} hostRef={hostRef}>
        <div {...contentProps}>
          {model.children?.map((id) => (
            <WidgetNode key={id} id={id} />
          ))}
        </div>
      </Addons>,
    );
  }

  const content = React.createElement(
    'w',
    contentProps,
    model.html || !Widget ? undefined : (
      <Widget section={section} {...model.content} hostRef={hostRef} />
    ),
  );
  // Need to use create element to get rid of error of not supported jsx element
  return React.createElement(
    'vev',
    {
      id: model.key,
      className: cl,
      style: model.style,
      ref: hostRef,
      key: model.key + model.actions?.join() || '',
    },
    <ErrorBounds>
      <div className="__wc">
        <Addons addons={model.actions} hostRef={hostRef}>
          {content && (
            <ModelProvider value={model}>
              {model.pin ? <div className="__pc">{content}</div> : content}
            </ModelProvider>
          )}
          {(section || (model.children?.length || 0) > 0) && (
            <div className={childrenCl}>
              {model.children?.map((id) => (
                <WidgetNode key={id} id={id} />
              ))}
            </div>
          )}
        </Addons>
      </div>
    </ErrorBounds>,
  );
}
export default WidgetNode;
